import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from "react-bootstrap";
import blob from '../assets/blob.svg';
import opito from '../assets/opito.png';
import stcw from '../assets/stcw.png';
import majkut from '../assets/majkut.png';
import kosa from '../assets/kosa.jpg';
import './About.css';
import LazyLoad from "react-lazyload";
import { PreventOrphanWords } from "../utils/func";

const About = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Row className="justify-content-between mt-4">
                <Col md={12} className="text-center">
                    <h1 className="fw-bold">{t('about.title')}</h1>
                    {/*<LazyLoad height={200} offset={100} once>*/}
                    {/*    <img src={blob} alt="blob" className="blob"/>*/}
                    {/*</LazyLoad>*/}
                    <PreventOrphanWords text={t('about.description')} />
                </Col>
            </Row>
            <Row className="justify-content-between mt-5">
                <Col md={6}>
                    <h1 className="fw-bold text-center text-md-start">{t('team.title')}</h1>
                    <PreventOrphanWords className="justify-text" text={t('team.description')} />
                </Col>
                <Col md={6}>
                    <img src={opito} alt="opito" width={200} height={200} className="img-fluid"/>
                    <img src={stcw} alt="stcw" width={400} height={200} className="img-fluid"/>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col xs={{ order: 2 }} md={{ span: 4, order: 1 }} className="text-center border-box-1 mt-2">
                    <LazyLoad height={200} offset={100} once>
                        <img src={kosa} alt="Photo" width={300} height={500} className="rounded img-fluid"/>
                    </LazyLoad>
                </Col>
                <Col xs={{ order: 1 }} md={{ span: 8, order: 2 }} className="m-auto">
                    <h1 className="fw-bold">{t('team.member1Name')}</h1>
                    <h3 className="fw-semibold">{t('team.member1Title')}</h3>
                    {/*<PreventOrphanWords text={t('team.member1Description')} />*/}
                </Col>
            </Row>
            <Row className="justify-content-between my-5">
                <Col xs={{ order: 1 }} md={{ span: 8, order: 1 }} className="m-auto text-md-end">
                    <h1 className="fw-bold">{t('team.member2Name')}</h1>
                    <h3 className="fw-semibold">{t('team.member2Title')}</h3>
                    {/*<PreventOrphanWords text={t('team.member2Description')} />*/}
                </Col>
                <Col xs={{ order: 2 }} md={{ span: 4, order: 2 }} className="text-center border-box-2">
                    <LazyLoad height={200} offset={100} once>
                        <img src={majkut} alt="Photo" width={300} height={500} className="rounded img-fluid"/>
                    </LazyLoad>
                </Col>
            </Row>
        </Container>
    );
};

export default About;
