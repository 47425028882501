export const PreventOrphanWords = ({ text, className }) => {
    const preventOrphans = (inputText) => {
        const styledSlash = '<span style="font-weight: bolder; color: rgb(0, 119, 182);"> / </span>';
        return inputText
            .replace(/NIP:/g, '<span style="color: gray; font-weight: normal">NIP:</span>')
            .replace(/REGON:/g, '<span style="color: gray; font-weight: normal">REGON:</span>')
            .replace(/ul\./g, '<span style="color: gray; font-weight: normal">ul.</span>')
            .replace(/ \/ /g, styledSlash)
            // .replace(/ ([a-zA-Z]) /g, ' $1' + '\u00A0')

            .replace(/ (\S{1,3}) /g, ' $1&nbsp;');
    };

    const formattedText = preventOrphans(text);

    return (
        <div className={className} style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: formattedText }} />
    );
};
