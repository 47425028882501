import React from 'react';
import {useTranslation} from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="mt-auto py-3 text-dark text-center" style={{backgroundColor: '#0077b6'}}>
            <div className="container d-flex justify-content-between">
                {t('copyright.short')}
                <a href="https://storyset.com/business" className="text-dark small">Business illustrations by Storyset</a>
            </div>
        </footer>
    );
};

export default Footer;
