import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';
import {Navbar, Nav, Image} from 'react-bootstrap';
// import logo from '../assets/logo_black.png';
import logo from '../assets/logo_white.png';

const Header = () => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => setExpanded(!expanded);
    const closeNav = () => setExpanded(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <Navbar style={{backgroundColor: '#0077b6'}} variant="dark" expand="lg" className="px-4" expanded={expanded}>
            <Navbar.Brand as={Link} to="/">
                <Image src={logo} alt="Logo" width="150" height="50" className="d-inline-block align-top" />
            </Navbar.Brand>
            <div className="language-burger">
                <div className="language m-auto">
                    <a onClick={() => changeLanguage('en')}>EN /</a>
                    <a onClick={() => changeLanguage('pl')}> PL</a>
                </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
            </div>
            <Navbar.Collapse id="basic-navbar-nav navbar-nav mr-auto">
                <Nav className="me-auto">
                    <Nav.Link as={Link} onClick={closeNav} to="/">{t('navbar.home')}</Nav.Link>
                    <Nav.Link as={Link} onClick={closeNav} to="/about">{t('navbar.about')}</Nav.Link>
                    <Nav.Link as={Link} onClick={closeNav} to="/services">{t('navbar.services')}</Nav.Link>
                    <Nav.Link as={Link} onClick={closeNav} to="/contact">{t('navbar.contact')}</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            <div className="language language-select m-auto">
                <a onClick={() => changeLanguage('en')}>EN /</a>
                <a onClick={() => changeLanguage('pl')}> PL</a>
            </div>
        </Navbar>
    );
};

export default Header;
